<template>
  <div class="form-url-container">
    <b-row class="w-100 m-0 align-items-center">
      <b-col
        lg="6"
        cols="12"
        class="p-0"
      >
        <h3 class="m-lg-0 mb-md-1">
          {{ $t('partnerView.titleEmbedFormInfoURL') }}
        </h3>
      </b-col>
      <b-col
        class="mt-1 mt-md-0 px-0 d-md-flex align-items-center"
        lg="6"
        cols="12"
      >
        <div class="w-100">
          <validation-observer
            ref="formValidation"
            v-slot="{ invalid }"
          >
            <b-form class="d-md-flex justify-content-between">
              <b-form-group class="m-0 w-100">
                <validation-provider
                  #default="{ errors }"
                  :name="$t('partnerView.textInfoUrl')"
                  rules="url|required|urlWithProtocols"
                >
                  <b-input-group
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      v-model.trim="infoURL"
                      id="url-info"
                      name="info URL"
                      class="input-height"
                      :placeholder="$t('partnerView.placeholderEnterInfoUrl')"
                      :state="errors.length > 0 ? false : null"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="ml-md-1 mt-1 mt-md-0">
                <btn-loading
                  class="btn-height btn-text-color"
                  type="submit"
                  variant-convert="btn-submit"
                  :disabled="displayBtnSave || invalid"
                  :loading="loading"
                  @click.prevent="handleSaveUrl"
                >
                  {{ $t('partnerView.btnSaveUrl') }}
                </btn-loading>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BInputGroup,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { url, required, urlWithProtocols } from '@validations'
import BtnLoading from '@/layouts/components/BtnLoading.vue'
import { createNamespacedHelpers } from 'vuex'
import { toastification } from '@core/mixins/toast'

const { mapActions, mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BInputGroup,
    BtnLoading,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [toastification],

  data() {
    return {
      infoURL: '',
      url,
      required,
      urlWithProtocols,
    }
  },

  computed: {
    ...mapGetters(['status', 'message', 'loading', 'user']),

    formEmbedURL() {
      return user => user?.data?.partnership?.formEmbedURL
    },

    displayBtnSave() {
      return this.infoURL === this.formEmbedURL(this.user)
    },
  },

  watch: {
    user: {
      handler(user) {
        if (this.formEmbedURL(user)) {
          this.infoURL = this.formEmbedURL(user)
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(['updateAccount', 'getAccountInfo']),

    async handleSaveUrl() {
      const partnership = {
        formEmbedURL: this.infoURL,
      }
      await this.updateAccount({ partnership })
      if (this.status) {
        this.toastSuccess(this.$t('partnerView.toastSaveUrlSuccessfully'))
        this.getAccountInfo()
        this.resetForm()
      } else {
        this.toastFailure(this.message)
      }
    },

    resetForm() {
      this.infoURL = ''
      this.$refs.formValidation.reset()
    },
  },
}
</script>

<style lang='scss' scoped>
.form-url-container {
  margin-bottom: 24px;
  background-color: #fff;
  border-radius: var(--border-radius-md);
  border-bottom: 1px solid rgba(22, 33, 62, 0.1);
  padding: 32px;

  @media(max-width: 767px) {
    padding: 16px;
  }

  .input-height{
    height: 40px;
  }

  .btn-height{
    height: 40px;
    width: max-content;
  }
}
</style>
