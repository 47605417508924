<template>
  <div class="form-info-container">

    <add-URL-partner-info />

    <preview-page />
  </div>
</template>

<script>
import AddURLPartnerInfo from './components/AddURLPartnerInfo.vue'
import PreviewPage from './components/previewPage.vue'

export default {
  components: {

    AddURLPartnerInfo,
    PreviewPage,
  },
}
</script>
